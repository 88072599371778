import { Component, OnInit } from '@angular/core';
import { BadgesService } from './modules/badges/badges.service';
import { SvgRegisterMatIconService } from './services/svg-register-mat-icon.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  title = 'prism-ui';

  // Injecting BadgesService to initialize the badge updates
  constructor(
    private badgesService: BadgesService,
    private svgRegisterMatIconService: SvgRegisterMatIconService
  ) { }

  ngOnInit(): void { }
}
