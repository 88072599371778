import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SvgRegisterMatIconService {

  private icons = [
    // assets/svg
    { name: 'ai-vector', path: 'assets/svg/ai-vector.svg' },

    // assets/icons
    { name: 'error-rounded-red', path: 'assets/icons/error-rounded-red.svg' },
    { name: 'warning-open', path: 'assets/icons/warning-open.svg' },
    { name: 'rejected-icon', path: 'assets/icons/rejected-icon.svg' },
    { name: 'skip-button', path: 'assets/icons/skip-button.svg' },
    { name: 'teams-icon', path: 'assets/icons/msTeams.svg' },
    { name: 'slack-icon', path: 'assets/icons/slack-new.svg' },
    { name: 'rejected-icon', path: 'assets/icons/rejected-icon.svg' },
    { name: 'info_italic', path: 'assets/icons/info.svg' },
    { name: 'polygon', path: 'assets/icons/polygon.svg' }
  ];

  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.registerIcons();
   }

   public registerCustomSvgIcon(name: string, path: string, viewBox: string = '0 0 24 24', color: string = 'currentColor') {
    fetch(path)
      .then(response => response.text())
      .then(svg => {
        const modifiedSvg = this.modifySvg(svg, viewBox, color);
        const sanitizedSvg = this.sanitizer.bypassSecurityTrustHtml(modifiedSvg);
        this.matIconRegistry.addSvgIconLiteral(name, sanitizedSvg);
      })
      .catch(error => console.error('Error fetching SVG:', error));
  }

   private modifySvg(svgString: string, viewBox: string, color: string): string {
    return svgString
      .replace(/<svg[^>]*>/, match => {
        let updatedSvg = match.includes('viewBox')
          ? match.replace(/viewBox="[^"]*"/, `viewBox="${viewBox}"`)
          : match.replace('>', ` viewBox="${viewBox}">`);

        updatedSvg = updatedSvg.replace(/fill="[^"]*"/g, `fill="${color}"`);
        return updatedSvg;
      });
  }

  private registerIcons() {
    this.icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }
}
