import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { replayIntegration, browserTracingIntegration, init } from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CoralogixBrowserSdkConfig, CoralogixRum } from '@coralogix/browser';


initCoralogix();
if (environment.production) {
  enableProdMode();
  initSentry();
  embedWhatfix();
}

function embedWhatfix() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://cdn.whatfix.com/prod/03bdd35b-c8ae-445e-9964-173d750fc504/initiator/initiator.nocache.js';
  document.head.appendChild(script);
}

function initCoralogix() {
  if(environment?.coralogix.public_key) {
    CoralogixRum.init({ 
      public_key: environment.coralogix.public_key,
      application: environment.coralogix.application,
      version: '1.0',
      coralogixDomain: 'AP1'
    });
  }
}

function initSentry() {
  init({
    dsn: "https://f39c843071074f109d46e3b3a2a42a81@o828115.ingest.sentry.io/5811526",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['https://' + environment.domain],
    beforeSend: (event) => {
      
      // Ignore non-standard errors 
      const exceptionMsgArray = [
        'Non-Error exception captured with keys',
        'ResizeObserver loop completed with undelivered notifications.'
      ]
      if (exceptionMsgArray.some(msg => event?.['message']?.includes(msg) || event?.['title']?.includes(msg))) {
        return null;
      }

      // Ignore errors from vendor files and anonymous errors which might indicate non-app related code
      if (event.exception?.values?.some(value =>
        value.stacktrace?.frames?.some(frame =>
          frame?.filename?.includes('/vendor.') || frame?.filename === "\u003Canonymous\u003E"
        ))) {
        return null;
      }

      return event;
    },
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false
      })
    ],
    environment: environment.domain,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
