import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { createErrorHandler } from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})

export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private router: Router) { }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      createErrorHandler({
        showDialog: false,
      })
      this.router.navigate(['/']);
      // window.location.reload();
    } else {
      createErrorHandler({
        showDialog: true,
      })
    }
    console.error(error);
  }
}
