import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { ImmediateBadgeUpdatesService } from '../services/immediate-badge-updates.service';
import { WaitErrorDialogsService } from '../services/wait-error-dialogs.service';
import { DIALOG_TYPES } from '../constants';

export function interceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const ds = inject(DataService);
  const immediateBadgeUpdates = inject(ImmediateBadgeUpdatesService);
  const weds = inject(WaitErrorDialogsService);
  return next(request).pipe(
    tap(result => {
      if (result?.['body']?.['status'] === 'Logout') {
        ds.onLogout();
      }
      // Handle user engagement data
      const userEngagement = result?.['body']?.['userEngagement'];
      if (userEngagement?.['data']) {
        const userEngagementData = userEngagement['data'];
        if (userEngagementData?.['badges']?.length > 0) {
          immediateBadgeUpdates.setBadgeUpdates(userEngagementData['badges']);
        }
      } else if (userEngagement?.['error']) {
        console.error(userEngagement['error']);
      }
    }),
    catchError((requestError: HttpErrorResponse) => {
      handleApiFailure(requestError, ds, weds);
      return throwError(() => requestError);
    })
  );
}

function handleApiFailure(error: HttpErrorResponse, ds: DataService, weds: WaitErrorDialogsService): void {
  switch (error?.status) {
    case 400:
      weds.showDialog({ type: DIALOG_TYPES.GENERIC, title: 'Error', message: error.error?.message ? error.error?.message : 'Something went wrong, Please try again later!', isApiFailureDialog: true });
      break;
    case 401:
    case 402:
      ds.onLogout();
      break;
    case 403:
      weds.showDialog({
        type: DIALOG_TYPES.GENERIC,
        code: 403,
        message: error.error.message,
        dontCloseAllDialogs: true,
        isApiFailureDialog: true,
      });
      break;
    default:
      weds.showDialog({
        type: DIALOG_TYPES.GENERIC,
        code: 0,
        dontCloseAllDialogs: true,
        isApiFailureDialog: true,
      });
      break;
  }
}
